
import { IonApp, IonRouterOutlet } from '@ionic/vue';//要在 Vue 中使用组件，必须先导入它，这里导入的就是内置IonApp和IonRouterOutlet组件
import { defineComponent } from 'vue'; //定义组件需要引入
// 1.引用proivde
import {provide} from "vue"
// 2.引用echarts
import * as echarts from "echarts"
// 引用axios
import axios from 'axios'
//设置请求基准路径方便修改
axios.defaults.baseURL = "https://www.attian.cn/api//"
//定义组件的名称name，然后提供将在模板中使用的组件components
export default defineComponent({
  setup(){
    //要为组件后代提供数据，需要使用到 provide() 函数
    provide("echarts",echarts)//第一个参数是名字  第二个参数是你传递的内容
    provide("axios",axios)//第一个参数是名字  第二个参数是你传递的内容
  },
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  }
});
