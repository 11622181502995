import {createApp} from 'vue'
import App from './App.vue'
import router from './router';

import axios from "axios";
import {IonicVue} from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

//引入图标
import '../public/assets/font/iconfont.css'
import '../public/assets/font/iconfont1'
import '../public/assets/font/iconfont2'

/* Theme variables */
import './theme/variables.css';

// import Viewer from "v-viewer";
// import "viewerjs/dist/viewer.css"

//引入vue懒加载

import VueLazyload from 'vue-lazyload'
import loading from '../public/assets/pictures/loading2.png'
import error from '../public/assets/pictures/loadingError.png'

const app = createApp(App)//createApp函数让我们初始化我们的 Vue 应用程序,传入了组件App(App.Vue中定义的)
    .use(IonicVue)//IonicVue是一个允许我们在 Vue 环境中使用 Ionic Framework 的插件。
    .use(router)
    .use(VueLazyload, {
        error: error,
        loading: loading,
    });

router.isReady().then(() => {
    app.mount('#app');
    app.config.globalProperties.$axios = axios;  //配置axios的全局引用
});
