<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="School" href="/tabs/School" @click="change($event)">
          <svg class="icon" aria-hidden="true" v-show="myflag[0]">
            <use xlink:href="#icon-SchoolCon2"></use>
          </svg>
          <svg class="icon" aria-hidden="true" v-show="myflag[1]">
            <use xlink:href="#icon-SchoolCon3"></use>
          </svg>
          <ion-laiicon-MyHomecon-MyHomebel>首页</ion-laiicon-MyHomecon-MyHomebel>
        </ion-tab-button>
          
        <ion-tab-button tab="CarbonFind" href="/tabs/CarbonFind" @click="change($event)">
          <svg class="icon" aria-hidden="true" v-show="myflag[2]">
            <use xlink:href="#icon-CarbonCon"></use>
          </svg>
          <svg class="icon" aria-hidden="true" v-show="myflag[3]">
            <use xlink:href="#icon-CarbonCon2"></use>
          </svg>
          <ion-label>碳觅</ion-label>
        </ion-tab-button>
        
        <ion-tab-button tab="Points" href="/tabs/Points" @click="change($event)">
          <svg class="icon" aria-hidden="true" v-show="myflag[4]">
            <use xlink:href="#icon-PointsCon"></use>
          </svg>
          <svg class="icon" aria-hidden="true" v-show="myflag[5]">
            <use xlink:href="#icon-PointsCon2"></use>
          </svg>
          <ion-label>积分乐园</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="MyHome" href="/tabs/MyHome" @click="change($event)">
          <svg class="icon" aria-hidden="true" v-show="myflag[6]">
            <use xlink:href="#icon-MyHome"></use>
          </svg>
          <svg class="icon" aria-hidden="true" v-show="myflag[7]">
            <use xlink:href="#icon-MyHome2"></use>
          </svg>
          <ion-label>我的</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import {IonTabBar, IonTabButton, IonTabs, IonLabel, IonPage, IonRouterOutlet} from '@ionic/vue';
import {Storage} from '@ionic/storage';



export default defineComponent({
  name: 'TabsPage',
  components: {IonLabel, IonTabs, IonTabBar, IonTabButton, IonPage, IonRouterOutlet},
  data() {
    return {
      myflag: [false, true, false, true, false, true, true, false]
    }
  },
  mounted() {
    const storage = new Storage();
    storage.create();
    let logIndex = storage.get("logIn");
    logIndex.then((result) => {
      if (result === 1) {
        console.log(result)
        this.myflag = [false, true, false, true, false, true, true, false]
      }
    })
  },
  methods: {
    change(a) {
      if (a.currentTarget.id === "tab-button-School") {
        this.myflag = [true, false, false, true, false, true, false, true];
      } else if (a.currentTarget.id === "tab-button-CarbonFind") {
        this.myflag = [false, true, true, false, false, true, false, true];
      } else if (a.currentTarget.id === "tab-button-Points") {
        this.myflag = [false, true, false, true, true, false, false, true];
      } else {
        this.myflag = [false, true, false, true, false, true, true, false];

      }
    }
  }
});
</script>
