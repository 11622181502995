import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/TabsPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/tabs/MyHome'
  },
  {
    path: '/tabs/',

    component: TabsPage,// 底边栏

    children: [
      {
        path: '',
        redirect: '/tabs/MyHome'
      },
      {
        path: 'School',
        component: () => import('@/views/School.vue')
      },
      {
        path: 'CarbonFind',
        component: () => import('@/views/CarbonFind.vue')
      },
      {
        path: 'Points',
        component: () => import('@/views/Points.vue')
      },
      {
        path:'MyHome',
        component:()=>import('@/views/MyHome.vue')
      },

      {
        path:'ActionPie',
        component:()=>import('@/components/ActionPieChart.vue')
      },
      {
        path:'slide',
        component:()=>import('@/components/SlideImage.vue')
      },
      {
        path:'top',
        component:()=>import('@/components/TopBar.vue')
      },
      {
        path:'tmp',
        component:()=>import('@/components/tanpuhui.vue')
      },
      {
        path:'88carbon',
        component:()=>import('@/components/88carbon.vue')
      },
      {
        path:'badge',
        component:()=>import('@/components/user/UserAchievement.vue')
      },
      {
        path:'rank',
        component:()=>import('@/components/user/UserRank.vue')
      },
      {
        path:'chart',
        component:()=>import('@/components/EmissionChartCHN.vue')
      },
      {
        path:'svg1',
        component:()=>import('@/components/question/CFShowQuestions.vue')
      },

    ]

  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
